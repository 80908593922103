@font-face {
    font-family: 'ApexSerif';
    src: url('./assets/fonts/ApexSerif-Bold.woff2') format('woff2'),
    url('./assets/fonts/ApexSerif-Bold.woff') format('woff'),
    url('./assets/fonts/ApexSerif-Bold.ttf') format('truetype'),
    url('./assets/fonts/ApexSerif-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ApexSerif';
    src: url('./assets/fonts/ApexSerif-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/ApexSerif-BoldItalic.woff') format('woff'),
    url('./assets/fonts/ApexSerif-BoldItalic.ttf') format('truetype'),
    url('./assets/fonts/ApexSerif-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'ApexSerif';
    src: url('./assets/fonts/ApexSerif-Book.woff2') format('woff2'),
    url('./assets/fonts/ApexSerif-Book.woff') format('woff'),
    url('./assets/fonts/ApexSerif-Book.ttf') format('truetype'),
    url('./assets/fonts/ApexSerif-Book.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ApexSerif';
    src: url('./assets/fonts/ApexSerif-BookItalic.woff2') format('woff2'),
    url('./assets/fonts/ApexSerif-BookItalic.woff') format('woff'),
    url('./assets/fonts/ApexSerif-BookItalic.ttf') format('truetype'),
    url('./assets/fonts/ApexSerif-BookItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PoplarKozel';
    src: url('./assets/fonts/PoplarKozel.woff2') format('woff2'),
    url('./assets/fonts/PoplarKozel.woff') format('woff'),
    url('./assets/fonts/PoplarKozel.ttf') format('truetype'),
    url('./assets/fonts/PoplarKozel.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NeonLight';
    src: url('./assets/fonts/neon_light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDateCalendar-root {
    background-color: #E3CB9F;
    color: #413121;
}

.MuiPickersLayout-contentWrapper {
    background-color: #E3CB9F;
    color: #413121;
}

.cluster_custom_low{

}

.cluster_custom_low > div{
    font-size: 14px !important;
    text-shadow: #E3CB9F 1px 0 10px;
    background-color: #E3CB9F55;
    border-radius: 100%;
}
.cluster_custom_medium{

}

.cluster_custom_medium > div{
    font-size: 16px !important;
    text-shadow: #E3CB9F 1px 0 10px;
    background-color: #E3CB9FAA;
    border-radius: 100%;
}

.cluster_custom_high{

}

.cluster_custom_high > div{
    font-size: 18px !important;
    text-shadow: #E3CB9F 1px 0 10px;
    background-color: #E3CB9F55;
    border-radius: 100%;
}
.gm-style-iw.gm-style-iw-c{
    background-color: #413121;
    border-width: 5px;
    border-style: dotted;
    border-color: #D7A461;
    border-radius: 25px;
}
.gm-style .gm-style-iw-tc::after {
    background-color: #413121 !important;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-chr{
    position: absolute;
    top: 0;
    right: 0;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-d{
    overflow: hidden !important;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-chr button{
    width: 30px !important;
    height: 30px !important;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-chr button span {
    background-color: #E3CB9F;
    margin: 0 !important;
}